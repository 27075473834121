import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { auth: true },
    component: () => import('@/views/Home'),
    children: [{
      path: '',
      name: 'dashboard',
      component: () => import('@/views/Usuarios')
    },
    {
      path: 'usuarios',
      name: 'usuarios',
      component: () => import('@/views/Usuarios')
    },
    {
      path: 'usuario/detalle/:usuarioId',
      name: 'usuario',
      component: () => import('@/views/Usuario')
    },
    {
      path: 'vacantes',
      name: 'vancantes',
      component: () => import('@/views/Vacantes')
    },
    {
      path: 'vacante/detalle/:vacanteId',
      name: 'vancante',
      component: () => import('@/views/Vacante')
    },
    {
      path: 'postulantes',
      name: 'postulantes',
      component: () => import('@/views/Postulantes')
    },
    {
      path: 'postulante/detalle/:postulanteId',
      name: 'postulante',
      component: () => import('@/views/Postulante')
    }]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(route => route.meta.auth)
  const authed = localStorage.getItem('access-token')
  const signInObject = { name: 'login' }

  if (requiresAuth && !authed) return next(signInObject)
  next()
})

export default router
